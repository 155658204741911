import React from 'react';
import './Filters.css';

const Filters = ({ onCategoryChange, onDateRangeChange, context }) => {
  return (
    <div className="filters d-flex align-items-center">
      <div className="form-group mr-2 mb-0">
        <select
          className="form-control"
          onChange={(e) => onCategoryChange(e.target.value)}
        >
          <option value="">All Categories</option>
          <option value="Card">Card</option>
          <option value="ID Card">ID Card</option>
          <option value="Bank Card">Bank Card</option>
          <option value="Chain/Necklace">Chain/Necklace</option>
          <option value="Ring/Earring">Ring/Earring</option>
          <option value="Documents/File">Documents/File</option>
          <option value="Headphones/EarPods">Headphones/EarPods</option>
          <option value="Key">Key</option>
          <option value="Mobile Phone">Mobile Phone</option>
          <option value="Money">Money</option>
          <option value="Motorcycle">Motorcycle</option>
          <option value="Pet Bird">Pet Bird</option>
          <option value="Pet">Pet</option>
          <option value="Shoes">Shoes</option>
          <option value="Shopping Bag">Shopping Bag</option>
          <option value="Bag/Backpack">Bag/Backpack</option>
          <option value="Slippers">Slippers</option>
          <option value="Sunglasses">Sunglasses</option>
          <option value="Wallet">Wallet</option>
          <option value="Watch">Watch</option>
          <option value="Box/Package">Box/Package</option>
          <option value="Power Bank">Power Bank</option>
          <option value="Baby Stroller">Baby Stroller</option>
          <option value="Helmet">Helmet</option>
          <option value="Wrong Bank Transfer">Wrong Bank Transfer</option>
          <option value="Others">Others</option>
        </select>
      </div>

      <div className="form-group b-0">
        <select
          className="form-control"
          onChange={(e) => onDateRangeChange(e.target.value)}
          defaultValue="" // Set "Today" as the default value
        >
          <option value="">All Time</option>
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="past_7_days">Past 7 Days</option>
          <option value="past_30_days">Past 30 Days</option>
        </select>
      </div>
    </div>
  );
};

export default Filters;

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './MyPage.css';
import Pagination from './Pagination';

const MyPage = () => {
  const [lostItems, setLostItems] = useState([]);
  const [foundItems, setFoundItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentLostPage, setCurrentLostPage] = useState(1);
  const [totalLostPages, setTotalLostPages] = useState(1);
  const [currentFoundPage, setCurrentFoundPage] = useState(1);
  const [totalFoundPages, setTotalFoundPages] = useState(1);

  const fetchItems = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const lostResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/lost_items/my?page=${currentLostPage}`, { headers });
      const foundResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/found_items/my?page=${currentFoundPage}`, { headers });

      setLostItems(lostResponse.data.items);
      setTotalLostPages(lostResponse.data.totalPages);
      setFoundItems(foundResponse.data.items);
      setTotalFoundPages(foundResponse.data.totalPages);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
    setIsLoading(false);
  }, [currentLostPage, currentFoundPage]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleDelete = async (id, isLostItem) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        const token = localStorage.getItem('token');
        const endpoint = isLostItem ? 'lost_items' : 'found_items';
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/${endpoint}/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        fetchItems();
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
  };

  const handleMarkAsFound = async (id) => {
    if (window.confirm("Are you sure this item has been found?")) {
      try {
        const token = localStorage.getItem('token');
        await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/lost_items/${id}`, {
          lost_item: { found: true }
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        fetchItems();
      } catch (error) {
        console.error('Error marking item as found:', error);
      }
    }
  };

  const handleMarkAsReturned = async (id) => {
    if (window.confirm("Are you sure this item has been returned?")) {
      try {
        const token = localStorage.getItem('token');
        await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/found_items/${id}`, {
          found_item: { returned: true }
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        fetchItems();
      } catch (error) {
        console.error('Error marking item as returned:', error);
      }
    }
  };


  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="mypage-container">
      <h2>My Lost Items</h2>
      {lostItems.length > 0 ? (
        <div className="items-grid">
          {lostItems.map(item => (
            <div key={item.id} className="item-card">
              <div className="item-thumbnail">
                {item.photos_urls && item.photos_urls.length > 0 ? (
                  <img
                    src={item.photos_urls[0].startsWith("http") ? item.photos_urls[0] : `${process.env.REACT_APP_BACKEND_URL}${item.photos_urls[0]}`}
                    alt={item.title}
                    className="item-image"
                  />
                ) : (
                  <img src="/default-image.jpg" alt="Default" className="default-image" />
                )}

              </div>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
              <div className="actions">
                <button onClick={() => handleDelete(item.id, true)} className="btn btn-danger btn-sm">
                  Delete
                </button>
                <button
                  onClick={() => handleMarkAsFound(item.id)}
                  className="btn btn-success btn-sm ml-2"
                  disabled={item.found}
                >
                  {item.found ? 'Item Found' : 'Mark as Found'}
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-items">You have no lost item reports.</p>
      )}
      {totalLostPages > 1 && (
        <Pagination
          currentPage={currentLostPage}
          totalPages={totalLostPages}
          onPageChange={setCurrentLostPage}
        />
      )}

      <h2>My Found Items</h2>
      {foundItems.length > 0 ? (
        <div className="items-grid">
          {foundItems.map(item => (
            <div key={item.id} className="item-card">
              <div className="item-thumbnail">
                {item.photos_urls && item.photos_urls.length > 0 ? (
                  <img
                    src={item.photos_urls[0].startsWith("http") ? item.photos_urls[0] : `${process.env.REACT_APP_BACKEND_URL}${item.photos_urls[0]}`}
                    alt={item.title}
                    className="item-image"
                  />
                ) : (
                  <img src="/default-image.jpg" alt="Default" className="default-image" />
                )}

              </div>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
              <div className="actions">
                <button onClick={() => handleDelete(item.id, false)} className="btn btn-danger btn-sm">
                  Delete
                </button>
                <button
                  onClick={() => handleMarkAsReturned(item.id)}
                  className="btn btn-primary btn-sm ml-2"
                  disabled={item.returned}
                >
                  {item.returned ? 'Item Returned' : 'Mark as Returned'}
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-items">You have no found item reports.</p>
      )}
      {totalFoundPages > 1 && (
        <Pagination
          currentPage={currentFoundPage}
          totalPages={totalFoundPages}
          onPageChange={setCurrentFoundPage}
        />
      )}
    </div>
  );
};

export default MyPage;

import React from 'react';
import './ItemDetailsModal.css';

const ItemDetailsModal = ({ item, isOpen, onClose }) => {
  if (!isOpen || !item) return null;



  const isItemFromAdmin = item.user.name.toLowerCase() === 'admin';
  const showLocation = item.location.toLowerCase() !== 'others';
  const displayCategory = item.category.toLowerCase() === 'others' ? 'Lost Item' : item.category;

  return (
    <div className="item-details-modal">
      <button className="modal-close-button" onClick={onClose}>×</button>
      <h4>{displayCategory}</h4>
      <p>{item.description}</p>
      {!isItemFromAdmin && (
        <>
          <h5>👤 {item.user.name}</h5>
          <h5>📞 {item.user.mobile}</h5>
        </>
      )}
      {showLocation && <h5>📍 {item.location}</h5>}
      {/* Add more details as needed */}
      {isItemFromAdmin && <p className="source-admin">Source: MV Lost & Found</p>}
    </div>
  );
};

export default ItemDetailsModal;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faViber, faFacebookF } from '@fortawesome/free-brands-svg-icons';

function Home() {
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    console.log("Component mounted. Checking for Google auth code in URL.");
    const exchangeAuthorizationCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get('code');
      console.log("Authorization code from URL:", authorizationCode);

      if (authorizationCode) {
        console.log("Authorization code found. Exchanging code for token.");
        try {
          const response = await fetch('https://lnfmvapi-4e4252de1e9b.herokuapp.com/verify_google_id_token', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code: authorizationCode }),
          });

          if (response.ok) {
            const data = await response.json();
            login(data.token);  // Update authentication state
            navigate('/');  // Redirect to home
          } else {
            console.error('Error in token exchange:', await response.text());
          }
        } catch (error) {
          console.error('Error exchanging authorization code:', error);

        }
        console.log("No authorization code found in URL.");
      }
    };

    exchangeAuthorizationCode();
  }, [login, navigate]);

  return (
    <div className="home-container">
      <h1 className="home-title">Welcome to lostnfoundmv</h1>
      <div className="inline-buttons-container">
        <a href="/lost-items" className="inline-button home-button-lost">LOST ITEMS</a>
        <a href="/found-items" className="inline-button home-button-found">FOUND ITEMS</a>
      </div>
      <a href="https://invite.viber.com/?g2=AQAPXkJanIkXMVIh9NHLZSPohpMyIRVUMV3h1cPzQvCrzYHq7yHl10PFHJP9XlJC" className="join-viber-button" target="blank">
        <FontAwesomeIcon icon={faViber} className="button-icon" />Viber Channel
      </a>
      <a href="https://www.facebook.com/lostnfoundmvpage" className="join-facebook-button" target="blank">
        <FontAwesomeIcon icon={faFacebookF} className="button-icon" />Facebook page
      </a>
    </div>
  );
}

export default Home;

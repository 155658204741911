import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';


const Login = () => {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
    }
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    const fullMobileNumber = `+960${mobile}`; // Prepend +960 to the mobile number

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}login`, { mobile: fullMobileNumber, password });
      if (response.data.logged_in) {
        login(response.data.token); // Store JWT token
        const redirectPath = location.state?.from || '/';
        navigate(redirectPath, { replace: true });
      } else {
        setError('Login failed. Please check your credentials.');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.response) {
        setError(err.response.data.error || 'An error occurred during login.');
      } else {
        setError('Network error, please try again.');
      }
    }
  };

  const handleGoogleLogin = () => {
    console.log("Initiating Google login process");
    const clientId = '686523251549-kh88m6vv8gf35482upvd175f5pc2van8.apps.googleusercontent.com';
    const redirectUri = 'https://lostnfoundmv.app'; // Redirect to the home page
    const scope = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
    const responseType = 'code';

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&response_type=${responseType}&access_type=offline&prompt=consent`;

    window.location.href = authUrl;
    console.log("Redirecting to Google auth URL:", authUrl);
  };

  useEffect(() => {
    console.log("Component mounted. Checking for Google auth code in URL.");
    const exchangeAuthorizationCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get('code');
      console.log("Authorization code from URL:", authorizationCode);

      if (authorizationCode) {
        console.log("Authorization code found. Exchanging code for token.");
        try {
          const response = await fetch('https://lnfmvapi-4e4252de1e9b.herokuapp.com/verify_google_id_token', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code: authorizationCode }),
          });

          if (response.ok) {
            const data = await response.json();
            login(data.token);  // Update authentication state
            navigate('/');  // Redirect to home
          } else {
            console.error('Error in token exchange:', await response.text());
          }
        } catch (error) {
          console.error('Error exchanging authorization code:', error);

        }
        console.log("No authorization code found in URL.");
      }
    };

    exchangeAuthorizationCode();
  }, [login, navigate]);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="card p-4" style={{ maxWidth: '400px', width: '100%' }}>
        <h2 className="text-center">Login</h2>
        {message && <div className="alert alert-info">{message}</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleSubmit} className="mt-3">
          <div className="mb-3">
            <label htmlFor="mobile" className="form-label">Enter your Mobile </label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">+960</span>
              <input
                type="text"
                className="form-control"
                id="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}

                required
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password </label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          <button
            type="submit"
            className="btn mt-2 w-100"
            style={{
              background: '#f8f9fa', // A light grey background that is not too stark
              color: '#333', // A dark color for the text for better contrast
              padding: '12px 20px', // Slightly larger padding for a touch-friendly design
              border: '1px solid #dee2e6', // A subtle border color
              borderRadius: '0.25rem', // Consistent with Bootstrap's default styling
              transition: 'background-color 0.15s ease-in-out, color 0.15s ease-in-out' // Smooth transition for hover effect
            }}
            onMouseOver={(e) => {
              e.target.style.background = '#e2e6ea'; // Slightly darker grey on hover for feedback
              e.target.style.color = '#333'; // Keep text color the same for simplicity
            }}
            onMouseOut={(e) => {
              e.target.style.background = '#f8f9fa'; // Revert to original background color
              e.target.style.color = '#333'; // Revert to original text color
            }}
          >
            Login with Mobile
          </button>
          <button
            onClick={handleGoogleLogin}
            className="btn mt-2 w-100"
            style={{
              background: '#4285F4', // Google's blue
              color: 'white', // White text for contrast
              padding: '12px 20px', // Comfortable padding
              border: 'none', // No border for a cleaner look
              borderRadius: '4px', // Slightly rounded corners
              fontSize: '16px', // Legible font size
              fontWeight: '500', // Medium font weight for readability
              boxShadow: '0 2px 4px rgba(0,0,0,0.25)', // Subtle shadow for depth
              fontFamily: "'Roboto', sans-serif", // Google's Roboto font for authenticity
              display: 'flex', // For aligning the icon and text
              alignItems: 'center', // Align items for a clean look
              justifyContent: 'center', // Center the contents
            }}
          >
            <i className="fab fa-google" style={{ marginRight: '10px', fontSize: '20px' }}></i>
            Continue with Google
          </button>

          <div className="text-center mt-3">
            <span style={{ color: '#6c757d' }}>Ready to join us?  </span>
            <a href="/signup" style={{ color: '#007bff', textDecoration: 'none' }}>
              Sign Up here!
            </a>
          </div>
        </form>
      </div>
    </div>
  );

};

export default Login;

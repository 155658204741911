import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import FoundItemForm from './FoundItemForm';
import { useAuth } from '../context/AuthContext';
import Pagination from './Pagination';
import Filters from './Filters';
import './FoundItemsGrid.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faShare } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';
import SearchBar from './SearchBar';
import ItemDetailsModal from './ItemDetailsModal';


const FoundItemsGrid = () => {
  const [foundItems, setFoundItems] = useState([]);
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const context = location.pathname.includes('/found-items') ? 'found' : 'found';
  const [selectedItem, setSelectedItem] = useState(null);


  const fetchFoundItems = useCallback(async (page = 1) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/found_items`, {
        params: { page, category: categoryFilter, dateRange: dateRangeFilter, search: searchTerm }
      });
      setFoundItems(response.data.items);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching Found items:', error);
    }
  }, [categoryFilter, dateRangeFilter, searchTerm]);

  const handleSearchSubmit = (searchValue) => {
    setSearchTerm(searchValue);
    setCurrentPage(1);
    fetchFoundItems(1, searchValue);
  };

  useEffect(() => {
    fetchFoundItems(currentPage);
  }, [currentPage, categoryFilter, dateRangeFilter, fetchFoundItems]);

  const handleAddButtonClick = () => {
    if (isLoggedIn) {
      setShowModal(true);
    } else {
      navigate('/login', { state: { from: '/found-items', message: 'Please log in to add a found item' } });
    }
  };

  const handlePageChange = (newPage) => setCurrentPage(newPage);


  return (
    <div>
      <div className={`container mt-4 ${selectedItem ? 'blur-background' : ''}`}>

        <div className="button-and-accordion-wrapper">
          <div className="btn-group-centered">
            <button className="btn btn-add-filter" onClick={() => setIsModalOpen(true)}>
              Filters
            </button>

            <button onClick={handleAddButtonClick} className="btn btn-add-news">
              <i className="bi bi-plus-lg"></i> Report Found Item
            </button>
          </div>

          <Modal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
            <SearchBar onSearch={handleSearchSubmit} />
            <Filters
              onCategoryChange={(category) => setCategoryFilter(category)}
              onDateRangeChange={(dateRange) => setDateRangeFilter(dateRange)}
              context={context}
            />
            {/* <button onClick={handleApplyFilters}>Apply</button> */}
          </Modal>

        </div>

        {showModal && <FoundItemForm onClose={() => setShowModal(false)} />}


        {foundItems.length === 0 ? (
          <p className="text-muted text-center">
            {dateRangeFilter === 'today' ? 'No Found items reported today yet.' : 'No items found.'}
          </p>
        ) : (
          <div className="row mt-3">
            {foundItems.map(item => (
              <div key={item.id} className="col-12 col-sm-6 col-lg-4 mb-4">
                <div className="card h-100 shadow-sm d-flex flex-column custom-card">
                  {item.returned && <div className="found-label">FOUND</div>}
                  <div className="card-img-top">
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      infiniteLoop
                      useKeyboardArrows
                      className="carousel-wrapper"
                    >
                      {item.photos_urls.map((url, index) => (
                        <div key={index} className="carousel-image-container">
                          <img
                            src={url.startsWith("http") ? url : `${process.env.REACT_APP_BACKEND_URL}${url}?c_scale,w_auto`}
                            alt={`Item ${item.id}`}
                            className="carousel-image"
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <div className="card-body">
                    <p className="card-text text-center">{item.description}</p>
                  </div>
                  {/* <div className="user-info text-center">
                    {item.user.mobile === '+9607000000' ? (
                      <button className="btn btn-outline-primary" disabled>
                        <FontAwesomeIcon icon={faShare} /> from MV Found & Found
                      </button>
                    ) : (
                      <a href={`tel:${item.user.mobile}`} className="btn btn-outline-success">
                        Contact {item.user.name} - {item.user.mobile}
                      </a>
                    )}
                  </div>
                  <div className="card-footer text-muted text-center">
                    Posted on: {new Date(item.created_at).toLocaleDateString()}
                  </div> */}
                  <button className="view-more-button" onClick={() => setSelectedItem(item)}>
                    View More
                  </button>


                </div>
              </div>
            ))}
          </div>
        )}

        {foundItems.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      <ItemDetailsModal
        item={selectedItem}
        isOpen={!!selectedItem}
        onClose={() => setSelectedItem(null)}
      />
    </div>
  );

};

export default FoundItemsGrid;
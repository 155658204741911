import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import useOutsideAlerter from '../hooks/useOutsideAlerter'; // Import the custom hook

const Navbar = () => {
  const { isLoggedIn, logout } = useAuth();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const navbarRef = useRef(null); // Create a ref for the navbar

  useOutsideAlerter(navbarRef, () => setIsNavCollapsed(true)); // Use the custom hook

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleLogout = () => {
    logout();
    handleNavCollapse();
  };


  return (
    <nav ref={navbarRef} className="navbar navbar-expand-lg navbar-light bg-light custom-navbar">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/" onClick={handleNavCollapse}>lostnfoundmv</Link>
        <button className={`navbar-toggler ${isNavCollapsed ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded={!isNavCollapsed} aria-label="Toggle navigation" onClick={handleNavCollapse}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/lost-items" onClick={handleNavCollapse}>Lost Items</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/found-items" onClick={handleNavCollapse}>Found Items</Link>
            </li>
            {isLoggedIn ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/my-page" onClick={handleNavCollapse}>My Page</Link>
                </li>
                <li className="nav-item" id="logoutNavItem">
                  <button className="nav-link  btn-link" onClick={handleLogout}>Logout</button>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/login" onClick={handleNavCollapse}>Login</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/signup" onClick={handleNavCollapse}>Sign Up</Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

// src/hooks/AlertBanner.js
import React, { useState, useEffect } from 'react';
import './AlertBanner.css'; // If you have a separate CSS file for styles

const AlertBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setShowBanner(window.innerWidth > 675);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  if (!showBanner) return null;

  return (
    <div className="alert-banner">
      <p>
        For a better experience, switch to mobile view!
      </p>
    </div>
  );
};

export default AlertBanner;

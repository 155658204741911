import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const maldivesMobileRegex = /^[79]\d{6}$/;

const UserRegistration = () => {
  const baseURL = 'https://lnfmvapi-4e4252de1e9b.herokuapp.com';
  const { login } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    password: '',
    passwordConfirmation: '',
    verificationCode: ''
  });
  const [message, setMessage] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSendCode = async (e) => {
    e.preventDefault();
    if (maldivesMobileRegex.test(formData.mobile)) {
      try {
        const response = await axios.post(`${baseURL}/send_code`, { mobile: `+960${formData.mobile}` });
        setMessage(response.data.message);
        setIsCodeSent(true);
      } catch (error) {
        setMessage(error.response.data.error);
      }
    } else {
      setMessage('Invalid mobile number. It should start with 7 or 9 and be 7 digits long.');
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/verify_code`, {
        mobile: `+960${formData.mobile}`,
        code: formData.verificationCode,
        user: {
          name: formData.name,
          password: formData.password,
          password_confirmation: formData.passwordConfirmation
        }
      });
      if (response.status === 201) {
        login(response.data.token);
        navigate('/');
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  const isFormValid = maldivesMobileRegex.test(formData.mobile) &&
    formData.name.trim() !== '' &&
    formData.password.trim() !== '' &&
    formData.password === formData.passwordConfirmation;

  const buttonStyle = {
    background: '#f8f9fa',
    color: '#333',
    padding: '12px 20px',
    border: '1px solid #dee2e6',
    borderRadius: '0.25rem',
    transition: 'background-color 0.15s ease-in-out, color 0.15s ease-in-out',
    opacity: isFormValid || isCodeSent ? 1 : 0.5,
  };

  const handleGoogleLogin = () => {
    console.log("Initiating Google login process");
    const clientId = '686523251549-kh88m6vv8gf35482upvd175f5pc2van8.apps.googleusercontent.com';
    const redirectUri = 'https://lostnfoundmv.app'; // Redirect to the home page
    const scope = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
    const responseType = 'code';

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&response_type=${responseType}&access_type=offline&prompt=consent`;

    window.location.href = authUrl;
    console.log("Redirecting to Google auth URL:", authUrl);
  };

  useEffect(() => {
    console.log("Component mounted. Checking for Google auth code in URL.");
    const exchangeAuthorizationCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get('code');
      console.log("Authorization code from URL:", authorizationCode);

      if (authorizationCode) {
        console.log("Authorization code found. Exchanging code for token.");
        try {
          const response = await fetch('https://lnfmvapi-4e4252de1e9b.herokuapp.com/verify_google_id_token', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code: authorizationCode }),
          });

          if (response.ok) {
            const data = await response.json();
            login(data.token);  // Update authentication state
            navigate('/');  // Redirect to home
          } else {
            console.error('Error in token exchange:', await response.text());
          }
        } catch (error) {
          console.error('Error exchanging authorization code:', error);

        }
        console.log("No authorization code found in URL.");
      }
    };

    exchangeAuthorizationCode();
  }, [login, navigate]);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="card p-4" style={{ maxWidth: '400px', width: '100%' }}>
        <h2 className="text-center">Members Registration</h2>
        <form onSubmit={isCodeSent ? handleVerifyCode : handleSendCode}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Name</label>
            <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="mobile" className="form-label">mobile</label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">+960</span>
              <input type="text" className="form-control" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="passwordConfirmation" className="form-label">Confirm Password</label>
            <input type="password" className="form-control" id="passwordConfirmation" name="passwordConfirmation" value={formData.passwordConfirmation} onChange={handleChange} />
          </div>
          {isCodeSent && (
            <div className="mb-3">
              <label htmlFor="verificationCode" className="form-label">Verification Code*</label>
              <input type="text" className="form-control" id="verificationCode" name="verificationCode" value={formData.verificationCode} onChange={handleChange} />
            </div>
          )}
          <button type="submit" className="btn mt-2 w-100" style={buttonStyle} disabled={!isFormValid && !isCodeSent}>
            {isCodeSent ? 'Verify and Register 🎉' : 'Send SMS Code'}
          </button>


        </form>
        <button
          onClick={handleGoogleLogin}
          className="btn mt-2 w-100"
          style={{
            background: '#4285F4', // Google's blue
            color: 'white', // White text for contrast
            padding: '12px 20px', // Comfortable padding
            border: 'none', // No border for a cleaner look
            borderRadius: '4px', // Slightly rounded corners
            fontSize: '16px', // Legible font size
            fontWeight: '500', // Medium font weight for readability
            boxShadow: '0 2px 4px rgba(0,0,0,0.25)', // Subtle shadow for depth
            fontFamily: "'Roboto', sans-serif", // Google's Roboto font for authenticity
            display: 'flex', // For aligning the icon and text
            alignItems: 'center', // Align items for a clean look
            justifyContent: 'center', // Center the contents
          }}
        >
          <i className="fab fa-google" style={{ marginRight: '10px', fontSize: '20px' }}></i>
          Continue with Google
        </button>

        {message && <div className="alert alert-info mt-3">{message}</div>}
      </div>
    </div>
  );
};

export default UserRegistration;

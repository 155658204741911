import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import './FoundItemForm.css';
import { useNavigate } from 'react-router-dom';

const FoundItemForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    title: '', description: '', category: '', location: '', photos: []
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'photos') {
      const selectedFiles = Array.from(files).slice(0, 2);
      setFormData({ ...formData, photos: selectedFiles });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    const formSubmissionData = new FormData();
    Object.keys(formData).forEach(key => {
      if (key === 'photos') {
        formData[key].forEach(file => formSubmissionData.append('found_item[photos][]', file));
      } else {
        formSubmissionData.append(`found_item[${key}]`, formData[key]);
      }
    });
    formSubmissionData.append('found_item[user_id]', user?.id);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/found_items`, formSubmissionData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming token is stored in localStorage
        }
      });
      navigate('/my-page'); // Close modal after successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="card p-4">
          <div className="modal-header">
            <h2 className="text-center mb-3">Report Found Item</h2>
            <button className="btn-close" onClick={onClose}></button>
          </div>
          <form onSubmit={(e) => {
            const confirmMessage = "Are you sure you want to proceed?";
            if (!window.confirm(confirmMessage)) {
              e.preventDefault();
            } else {
              handleSubmit(e);
            }
          }}>
            <div className="row">
              <div className="col-md-12 mb-3">
              </div>
              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label htmlFor="category">Found Item Category</label>
                  <select
                    className="form-control"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled hidden>Select Category</option>
                    <option value="Card">Card</option>
                    <option value="ID Card">ID Card</option>
                    <option value="Bank Card">Bank Card</option>
                    <option value="Bag/Backpack">Bag/Backpack</option>
                    <option value="Chain/Necklace">Chain/Necklace</option>
                    <option value="Ring/Earring">Ring/Earring</option>
                    <option value="Documents/File">Documents/File</option>
                    <option value="Headphones/EarPods">Headphones/EarPods</option>
                    <option value="Key">Key</option>
                    <option value="Mobile Phone">Mobile Phone</option>
                    <option value="Money">Money</option>
                    <option value="Motorcycle">Motorcycle</option>
                    <option value="Pet Bird">Pet Bird</option>
                    <option value="Pet">Pet</option>
                    <option value="Shoes">Shoes</option>
                    <option value="Shopping Bag">Shopping Bag</option>
                    <option value="Slippers">Slippers</option>
                    <option value="Sunglasses/Glasses">Sunglasses/Glasses</option>
                    <option value="Wallet">Wallet</option>
                    <option value="Watch">Watch</option>
                    <option value="Box/Package">Box/Package</option>
                    <option value="Power Bank">Power Bank</option>
                    <option value="Baby Stroller">Baby Stroller</option>
                    <option value="Helmet">Helmet</option>
                    <option value="Wrong Bank Transfer">Wrong Bank Transfer</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="description">Description/Message</label>
              <textarea
                className="form-control muted-placeholder"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                placeholder="Describe the item and share contact details for responses."
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="location">Found Location 📍</label>
              <select
                className="form-control"
                id="location"
                name="location"
                onChange={handleChange}
                value={formData.location}
                required
              >
                <option value="">Select a location</option>
                <option value="Male City">Male City</option>
                <option value="Hulhumale Phase 1">Hulhumale Phase 1</option>
                <option value="Hulhumale Phase 2">Hulhumale Phase 2</option>
                <option value="Vili Male">Vili Male</option>
                <option value="Addu City">Addu City</option>
                <option value="Velana Airport">Velana Airport</option>
                <option value="Sinamalé Bridge">Sinamalé Bridge</option>
                <option value="Found in Taxi">Found in Taxi</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <div className="form-group mb-4">
              <label htmlFor="photo">Photo (Max 2 images, No video)</label>
              <input
                type="file"
                className="form-control"
                id="photos"
                name="photos"
                onChange={handleChange}
                multiple
              />
              <p className="text-muted small mt-2">
                Reminder: Do not include sensitive personal details (ID numbers,DOB,Card details) in your images. For ID Card Category images, we'll automatically soft blur faces for privacy.
              </p>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn w-100"
                style={{
                  background: '#f8f9fa',
                  color: '#333',
                  padding: '12px 20px',
                  border: '1px solid #dee2e6',
                  borderRadius: '0.25rem',
                  transition: 'background-color 0.15s ease-in-out, color 0.15s ease-in-out',
                  opacity: isSubmitting ? 0.5 : 1
                }}
                disabled={isSubmitting}
                onMouseOver={(e) => {
                  if (!isSubmitting) {
                    e.target.style.background = '#e2e6ea';
                  }
                }}
                onMouseOut={(e) => {
                  if (!isSubmitting) {
                    e.target.style.background = '#f8f9fa';
                  }
                }}
              >
                {isSubmitting ? (
                  <>
                    <div className="spinner"></div> {/* Spinner icon */}
                    <span>Submitting...</span>
                  </>
                ) : 'Submit Found Report'}
              </button>
              <p className="found-item-report">
                Post will be shared in our
                <a href="https://invite.viber.com/?g2=AQAPXkJanIkXMVIh9NHLZSPohpMyIRVUMV3h1cPzQvCrzYHq7yHl10PFHJP9XlJC"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link">
                  Viber
                </a>
                channel and on our
                <a href="https://www.facebook.com/lostnfoundmvpage"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link">
                  Facebook
                </a>
                page.
              </p>

            </div>
          </form>
        </div>

      </div>
    </div>
  );
};


export default FoundItemForm;

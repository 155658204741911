import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa'; // Importing search icon from react-icons

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchTerm);
  };

  return (
    <form onSubmit={handleSubmit} className="input-group search-bar">
      <input
        type="text"
        className="form-control"
        placeholder="Search items..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="input-group-append">
        <button className="btn search-btn" type="submit">
          <FaSearch />{/* Displaying search icon */}
        </button>

      </div>
    </form>
  );
};

export default SearchBar;

// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Signup from './components/Signup';
import Home from './components/Home';
import LostItemsGrid from './components/LostItemsGrid';
import FoundItemsGrid from './components/FoundItemsGrid';
import MyPage from './components/MyPage';
import { AuthProvider } from './context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import AlertBanner from './hooks/AlertBanner';
import { GoogleOAuthProvider } from '@react-oauth/google';

function AppWithRouter() {
  const location = useLocation();

  return (
    <>
      <GoogleOAuthProvider clientId="686523251549-kh88m6vv8gf35482upvd175f5pc2van8.apps.googleusercontent.com
">
        <AlertBanner />
        {location.pathname !== '/' && <Navbar />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/lost-items" element={<LostItemsGrid />} />
          <Route path="/found-items" element={<FoundItemsGrid />} />
          <Route path="/my-page" element={<MyPage />} />
        </Routes>
      </GoogleOAuthProvider>
    </>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppWithRouter />
      </AuthProvider>
    </Router>
  );
}

export default App;

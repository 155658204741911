import React from 'react';
import './Modal.css'; // You'll create this

const Modal = ({ children, isOpen, closeModal }) => {
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`} onClick={closeModal}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
